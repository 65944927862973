// EditorPage.js
import React, { useState } from 'react';
import FileControls from '../../components/FileControls/FileControls';
import TextEditor from '../../components/TextEditor/TextEditor';
import PdfControls from '../../components/PdfControls/PdfControls';
import Profile from '../../components/Profile/Profile';
const beautifyHtml = require('js-beautify').html;

const EditorPage = () => {
  const [filePath, setFilePath] = useState('');
  const [content, setContent] = useState('');
  const [originalContent, setOriginalContent] = useState(''); // State for the original content

  const [beforeBody, setBeforeBody] = useState('');
  const [afterBody, setAfterBody] = useState('');

  const triggerFileDownload = (formattedFilePath) => {
    const REACT_APP_BACKEND_ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
    fetch(`${REACT_APP_BACKEND_ENDPOINT}/triggerDownload?path=${formattedFilePath}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          const extractedData = extractHeadAndBody(data.fileContent);
          setContent(extractedData.bodyContent);
          setOriginalContent(data.fileContent); // Store the original content
          setBeforeBody(extractedData.beforeBody); // Store the head content
          setAfterBody(extractedData.afterBody); // Store the body attributes
        } else {
          alert('Error downloading the file.');
          console.log(data);
        }
      })
      .catch((error) => {
        console.error('Error triggering download:', error);
        alert('Error triggering download.');
      });
  };

  // Function to extract head content and opening body tag with attributes
  const extractHeadAndBody = (htmlContent) => {
  
    const headRegex = /([\s\S]*?<body.*?>)/i;
    const headMatch = htmlContent.match(headRegex);
    const beforeBody = headMatch ? headMatch[1] : '<!DOCTYPE html><html><head></head>';

    const bodyRegex = /<body.*?>([\s\S]*?)<footer.*?>/i;
    const bodyMatch = htmlContent.match(bodyRegex);
    const bodyContent = bodyMatch ? bodyMatch[1] : '<body>';

    const afterBodyRegex = /(<footer.*?>[\s\S]*?<\/footer>)([\s\S]*)/i;
    const afterBodyMatch = htmlContent.match(afterBodyRegex);
    const afterBody = afterBodyMatch ? (afterBodyMatch[1] || '') + (afterBodyMatch[2] || '') : '';

    return { beforeBody, afterBody, bodyContent };

  };
  
  
  const saveContentEdits = () => {
    
    const REACT_APP_BACKEND_ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;

    // Construct the full HTML content
    const fullContent = `${beforeBody}${content}${afterBody}`;
    const beautifiedEdited = beautifyHtml(fullContent, {
      indent_size: 2,
      space_in_empty_paren: true,
      // other options as needed
    });


    // Logic to save the edited content back to the server
    fetch(`${REACT_APP_BACKEND_ENDPOINT}/saveEditedFile?path=${filePath}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ originalContent, editedContent: beautifiedEdited }), // Send both original and edited content
    })
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        setOriginalContent(data.fileContent);
        alert('File saved successfully!');
      } else {
        alert('Error saving file.');
      }
    })
    .catch((error) => {
      console.error('Error saving edits:', error);
      alert('Error saving edits.');
    });
  };

  const savePDF = () =>{

  }

  return (
    <div>
      <FileControls
        filePath={filePath}
        setFilePath={setFilePath}
        triggerFileDownload={triggerFileDownload}
        saveContentEdits={saveContentEdits}
      />
      
      <TextEditor
        content={content}
        onContentChange={setContent}

      />
      <PdfControls/>
    </div>
  );
};

export default EditorPage;
