import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'antd';
import './Header.css';
import logo from '../../Assets/cropped-ttsiteicon.png';

const Header = () => {
  const { isAuthenticated, user } = useAuth0();

  return (
    <nav className="header">
      <div className="header-logo">
        <img id= 'teiga-logo' src={logo} alt="Teiga Tech Logo" />
        <div id='teiga-text-logo'>Teiga Tech <br/><div id='sub-text'>FTP-EZ</div></div>
      </div>
      <div className="header-controls">
        {isAuthenticated ? (
          <>
            <span className="header-greeting">Welcome, {user.name}!</span>
            <LogoutButton />
          </>
        ) : (
          <LoginButton />
        )}
      </div>
    </nav>
  );
};

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button type="primary" size="large" onClick={() => loginWithRedirect()}>
      Log In
    </Button>
  );
};

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <Button type="default" size="large" onClick={() => logout({ returnTo: window.location.origin })}>
      Log Out
    </Button>
  );
};

export default Header;