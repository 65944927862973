// FileControls.js
import React from 'react';
import { Button, Input } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import './fileControls.css';

const FileControls = ({ filePath, setFilePath, triggerFileDownload, saveContentEdits }) => {

  // Function to format the URL entered by the user
  const formatUrlBeforeDownload = () => {
    // Format the URL
    const formattedFilePath = formatLink(filePath);
    // Set the formatted URL back to state
    setFilePath(formattedFilePath);
    // Trigger the file download with the formatted URL
    triggerFileDownload(formattedFilePath);
  };

  // Function to format the URL
  const formatLink = (url) => {
    // Remove http://, https://, and www.
    let formattedUrl = url.replace(/^(https?:\/\/)?(www\.)?/, '');

    // Check if the URL ends with a file type, if not, append 'index.html'
    if (!/\.\w+$/.test(formattedUrl)) {
      formattedUrl += 'index.htm';
    }

    return formattedUrl;
  };

  return (
    <div id="controls" style={{ marginBottom: '20px' }}>
      <Input
        size='large'
        type="text"
        value={filePath}
        onChange={(e) => setFilePath(e.target.value)}
        placeholder="Enter URL you want to edit"
        id='input_filepath'
        prefix={<UserOutlined />}
      />

      <div id='btn_filecontrol'>
        <Button id='btn_primary' type="primary" onClick={formatUrlBeforeDownload}>Display File To Edit</Button>
        <Button type="default" onClick={saveContentEdits}>Save Edits</Button>
      </div>
    </div>
  );
};

export default FileControls;
