// src/components/PdfControls/PdfControls.js
import React, { useState } from 'react';
import { Upload, Button, message, Radio } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import CustomUpload from '../CustomUpload/CustomUpload';
import './PdfControls.css';

function PdfControls() {
  const [fileList, setFileList] = useState([]);
  const [uploadDestination, setUploadDestination] = useState(null);

  const handleDestinationChange = (e) => {
    setUploadDestination(e.target.value);
  };

  const REACT_APP_BACKEND_ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
  const props = {
    action: `${REACT_APP_BACKEND_ENDPOINT}/uploadPdf`, // Your backend endpoint
    data: {
      destination: uploadDestination, // This will send the selected destination along with the file
    },
    beforeUpload: (file) => {
      const isPdf = file.type === 'application/pdf';
      if (!isPdf) {
        message.error('You can only upload PDF files!');
      }
      return isPdf || Upload.LIST_IGNORE;
    },
    onChange: (info) => {
      let newFileList = [...info.fileList];

      // Only show the latest uploaded file
      newFileList = newFileList.slice(-1);

      // Read from response and show file link
      newFileList = newFileList.map((file) => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.url;
        }
        return file;
      });

      setFileList(newFileList);

      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
    beforeUpload: (file) => {
      const isPdf = file.type === 'application/pdf';
      if (!isPdf) {
        message.error('You can only upload PDF files!');
        return Upload.LIST_IGNORE;
      }

      // Get current year and time in 24-hour format
      const currentYear = new Date().getFullYear();
      const currentTime = new Date().toLocaleTimeString('en-GB', { hour12: false }).replace(/:/g, '-');

      // Extract the file extension
      const fileExtension = file.name.split('.').pop();

      // Create a new file name without the extension
      const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, '');

      // Append current year and time to the file name, then add the extension
      const newFileName = `${fileNameWithoutExtension}_${currentYear}_${currentTime}.${fileExtension}`;
      const newFile = new File([file], newFileName, { type: file.type });

      // Update the file list
      setFileList([{
        uid: file.uid, // Keep the original file UID
        name: newFileName,
        status: 'done',
        url: URL.createObjectURL(newFile)
      }]);

      return newFile;
    },

    fileList,
  };

  return (
    <div>
      <h3>If you would like to link a member to a PDF, please select where you want the PDF uploaded so we can generate a link on the server for you</h3>
      <hr />
      <Radio.Group onChange={handleDestinationChange} value={uploadDestination}>
        <Radio.Button value="TCC">TCC</Radio.Button>
        <Radio.Button value="DL">DL</Radio.Button>
        <Radio.Button value="S16">S16</Radio.Button>
        <Radio.Button value="CSCOM">CSCOM</Radio.Button>
      </Radio.Group>
      <br /><br />
      <Upload {...props} accept=".pdf" disabled={!uploadDestination}>
        <Button icon={<UploadOutlined />} disabled={!uploadDestination}>
          Click to Upload PDF
        </Button>
      </Upload>
      <div>
        {fileList.length > 0 && (
          <div id='pdf_upload_message'>
            <strong>Link to the PDF you uploaded, so you can create a link:</strong>
            {fileList.map((file) => {
              // Function to remove the first segment of the file URL
              const processUrl = (url) => {
                if (!url) return ''; // Return an empty string if the URL is undefined
                const parts = url.split('/');
                parts.splice(0, 2); // Remove the first two elements
                return '/' + parts.join('/'); // Rejoin the remaining parts
              };

              // Check if file.url exists before processing
              const processedUrl = file.url ? processUrl(file.url) : '';
              const linkURL = 'https://' + file.url;

              return (
                <h3 key={file.uid}>
                  {/* Only render the link if file.url exists */}
                  {file.url && (
                    <a href={linkURL} target="_blank" rel="noopener noreferrer">{processedUrl}</a>
                  )}
                </h3>
              );
            })}
          </div>
        )}
      </div>
      <hr />
      <CustomUpload backendEndpoint={REACT_APP_BACKEND_ENDPOINT} />
    </div>
  );
}

export default PdfControls;
