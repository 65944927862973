
import React, { useState, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Button } from 'antd';

const TextEditor = ({ content, onContentChange }) => {
  const [bodyContent, setBodyContent] = useState('');

  useEffect(() => {
    // Extract only the body content
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    const bodyElement = doc.querySelector('body');
    setBodyContent(bodyElement ? bodyElement.innerHTML : '');
  }, [content]);

  const handleEditorChange = (newBodyContent) => {
    setBodyContent(newBodyContent);
    onContentChange(newBodyContent); // Update only with the body content
  };

  const checkForSpecialCharacter = () => {
    if (bodyContent.includes('�')) {
      alert('Special character � found in the content.');
    } else {
      alert('No special character � found.');
    }
  };

  return (
    <div>
      <Button onClick={checkForSpecialCharacter}>Check for Special Character</Button>
      <Editor
        apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
        value={bodyContent}
        onEditorChange={handleEditorChange}
        init={{
          height: 500,
          menubar: true,
          plugins: 'advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help wordcount',
          toolbar: 'undo redo | formatselect | bold italic backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | help | link',
          link_context_toolbar: true,
          valid_elements: '*[*]',
          extended_valid_elements: 'body[*],script[src|async|defer|type|charset],iframe[*]',
          forced_root_block: '',
          force_br_newlines: true,
          force_p_newlines: false,
          convert_urls: false,
          relative_urls: false,
          remove_linebreaks: false,
          apply_source_formatting: false,
          fix_list_elements: false,
          cleanup: false,
          link_context_toolbar: true,
          protect: [
            /<\/*(script|style|iframe|object|embed|param|video|audio|img|link)[^>]*>/g,
          ],
        }}
      />
    </div>
  );
};

export default TextEditor;


// import React, { useState, useEffect } from 'react';
// import { Editor } from '@tinymce/tinymce-react';
// import { Button, Input } from 'antd';
// import { UserOutlined } from '@ant-design/icons';

// function extractContent(htmlContent, tag) {
//   const parser = new DOMParser();
//   const doc = parser.parseFromString(htmlContent, 'text/html');
//   const element = doc.querySelector(tag);
//   return element ? { outerHTML: element.outerHTML, attributes: element.attributes } : { outerHTML: '', attributes: null };
// }

// const TextEditor = ({ content, onContentChange }) => {
//   const [bodyContent, setBodyContent] = useState('');
//   const [bodyAttributes, setBodyAttributes] = useState(null);
//   const [headContent, setHeadContent] = useState('');

//   useEffect(() => {
//     const headData = extractContent(content, 'head');
//     const bodyData = extractContent(content, 'body');
//     setHeadContent(headData.outerHTML);
//     setBodyContent(bodyData.outerHTML);
//     setBodyAttributes(bodyData.attributes);
//   }, [content]);

//   const handleEditorChange = (newBodyContent) => {
//     setBodyContent(newBodyContent);
//     const bodyAttributesString = bodyAttributes ? Array.from(bodyAttributes).map(attr => `${attr.name}="${attr.value}"`).join(' ') : '';
//     const fullContent = `<html><head>${headContent}</head><body ${bodyAttributesString}>${newBodyContent}</body></html>`;
//     onContentChange(fullContent);
//   };

//   const checkForSpecialCharacter = () => {
//     if (bodyContent.includes('�')) {
//       alert('Special character � found in the content.');
//     } else {
//       alert('No special character � found.');
//     }
//   };