import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import EditorPage from './pages/EditorPage/EditorPage.js';
import Header from './components/Header/Header.js';
import DeveloperPage from './pages/DeveloperPage/DeveloperPage.js';
import { Button } from 'antd';
import './App.css'; // Assuming you have global styles here

function App() {
  const { isAuthenticated } = useAuth0();
  const [useDeveloperPage, setUseDeveloperPage] = useState(true); // Set default to true

  const asciiTiger = `
    /\\_/\\  
  ( o.o ) 
  > ^ <
`;

  const asciiStyle = {
    textAlign: 'center'
  };
  const loginMessage = "You need to be logged in to view this content.";

  const togglePage = () => {
    setUseDeveloperPage(!useDeveloperPage);
  };

  return (
    <div className="App">
      <header className="App-header">
        <Header />
        {/* Toggle button to switch between EditorPage and DeveloperPage */}
        <Button onClick={togglePage} id='editor_toggle_button'>
          {useDeveloperPage ? 'Switch to Editor Page' : 'Switch to Developer Page'}
        </Button>
      </header>
      <main className="main-content">
        {isAuthenticated ? (
          useDeveloperPage ? <DeveloperPage /> : <EditorPage />
        ) : (
          <>
            <pre style={asciiStyle}>{asciiTiger}</pre>
            <h1 style={asciiStyle}>{loginMessage}</h1>
          </>
        )}
      </main>
      <footer>
        {/* And maybe a common footer */}
      </footer>
    </div>
  );
}

export default App;
