import React, { useState, useEffect } from 'react'; // Import useEffect here
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import { Button } from 'antd';
import 'prismjs/themes/prism.css'; // Example style, you can use another
import 'prismjs/components/prism-markup'; // Import for HTML

const DeveloperEditor = ({ content, onContentChange }) => {
  const [editorContent, setEditorContent] = useState(content);

  // Update the editor content when the content prop changes
  useEffect(() => {
    setEditorContent(content);
  }, [content]);

  const handleEditorChange = (newContent) => {
    setEditorContent(newContent);
    onContentChange(newContent); // Update with the full content
  };

  const checkForSpecialCharacter = () => {
    if (editorContent.includes('�')) {
      alert('Special character � found in the content.');
    } else {
      alert('No special character � found.');
    }
  };

  return (
    <div style={{ height: '50vh', overflow: 'auto', border: '1px solid black' }}>
      <Editor
        value={editorContent}
        onValueChange={handleEditorChange}
        highlight={code => highlight(code, languages.markup)}
        padding={10}
        style={{
          fontFamily: '"Fira code", "Fira Mono", monospace',
          fontSize: 12,
          // minHeight: '500px', // Set a minimum height for the editor
          // border: '1px solid black',
          // height: '50vh',
          // overflow:'auto'
        }}
      />
    </div>
  );
};

export default DeveloperEditor;
