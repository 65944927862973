import React, { useState } from 'react';
import FileControlsDeveloper from '../../components/FileControlsDeveloper/FileControlsDeveloper';
import DeveloperEditor from '../../components/DeveloperEditor/developerEditor';
import PdfControls from '../../components/PdfControls/PdfControls';
const beautifyHtml = require('js-beautify').html;

const DeveloperPage = () => {
  const [filePath, setFilePath] = useState('');
  const [originalHtmlContent, setOriginalHtmlContent] = useState('');
  const [editedHtmlContent, setEditedHtmlContent] = useState('');

  const triggerFullFileDownload = (filePath) => {
    const REACT_APP_BACKEND_ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
    fetch(`${REACT_APP_BACKEND_ENDPOINT}/triggerDownload?path=${filePath}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          const prettyHtmlContent = beautifyHtml(data.fileContent, {
            indent_size: 2,
            space_in_empty_paren: true,
            // other options as needed
          });
          setOriginalHtmlContent(data.fileContent);
          setEditedHtmlContent(prettyHtmlContent);
        } else {
          alert('Error downloading the file.');
        }
      })
      .catch((error) => {
        console.error('Error triggering full download:', error);
        alert('Error triggering full download.');
      });
  };

  const handleContentChange = (newContent) => {
    setEditedHtmlContent(newContent);
  };

  const saveContentEdits = () => {
    const REACT_APP_BACKEND_ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;

    if (!filePath) {
      alert('File path is not set.');
      return;
    }

    fetch(`${REACT_APP_BACKEND_ENDPOINT}/saveEditedFile?path=${encodeURIComponent(filePath)}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ originalContent: originalHtmlContent, editedContent: editedHtmlContent })
    })
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        setOriginalHtmlContent(editedHtmlContent);
        alert('File saved successfully!');
      } else {
        alert('Error saving file: ' + data.message);
      }
    })
    .catch((error) => {
      console.error('Error saving edits:', error);
      alert('Error saving edits: ' + error.message);
    });
  };

  return (
    <div>
      <FileControlsDeveloper
        filePath={filePath}
        setFilePath={setFilePath}
        triggerFileDownload={triggerFullFileDownload}
        saveContentEdits={saveContentEdits}
      />

      <DeveloperEditor
        content={editedHtmlContent}
        onContentChange={handleContentChange}
      />

      <PdfControls />
    </div>
  );
};

export default DeveloperPage;
