import React, { useState } from 'react';
import { Upload, Button, Input, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const CustomUpload = ({ backendEndpoint }) => {
  const [customFile, setCustomFile] = useState(null);
  const [customUploadUrl, setCustomUploadUrl] = useState('');

  const handleCustomFileChange = (info) => {
    setCustomFile(info.file);
  };

  const handleCustomUploadUrlChange = (e) => {
    setCustomUploadUrl(e.target.value);
  };

  const handleCustomUpload = async () => {
    if (!customFile || !customUploadUrl) {
      message.error('Please select a file and enter a valid upload URL.');
      return;
    }

    const formData = new FormData();
    formData.append('file', customFile);
    formData.append('destination', customUploadUrl);

    try {
      const response = await fetch(`${backendEndpoint}/customUpload`, {
        method: 'POST',
        body: formData,
      });

      const result = await response.json();

      if (response.ok && result.success) {
        message.success(`${customFile.name} file uploaded successfully: ${result.url}`);
      } else {
        message.error(`${customFile.name} file upload failed: ${result.message}`);
      }
    } catch (error) {
      message.error(`File upload failed: ${error.message}`);
    }
  };

  return (
    <div>
      <h3>Custom Upload</h3>
      <p> Please follow this format when entering URL : <strong>website.com/folder/Upload-Here/</strong></p>
      <Input
        placeholder="Enter the full URL of the location to upload"
        value={customUploadUrl}
        onChange={handleCustomUploadUrlChange}
      />
      <Upload
        beforeUpload={() => false} // Prevent automatic upload
        onChange={handleCustomFileChange}
        showUploadList={false}
      >
        <Button icon={<UploadOutlined />}>Select File</Button>
      </Upload>
      <Button type="primary" onClick={handleCustomUpload} disabled={!customFile || !customUploadUrl}>
        Upload Custom File
      </Button>
    </div>
  );
};

export default CustomUpload;
